<template>
  <el-row>
    <el-col :span="24">
      <ul>
        <li style="position: relative;"><span class="el-icon-tickets"></span><span style="margin-left: 15px;">AIO-5-用户手册-v1.0</span>
          <a href="#" style="width: 55px; height: 20px; position: absolute; right: 0; top:50%; transform: translateY(-50%); line-height: 1; color:#0088FE;">PDF <span class="el-icon-download"></span> </a>
        </li>
        <li>2</li>
        <li>3</li>
        <li>1</li>
        <li>2</li>
        <li>3</li>
        <li>1</li>
        <li>2</li>
        <li>3</li>
      </ul>
      <div style="text-align: center; margin-top: 200px;">
        <p style="font-weight: 500; font-size: 35px; text-align: center;">联系我们</p>
        <img :src="img_erwei" alt="二维码" class="img_resize">
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      img_erwei: require('../../../../../public/images/support/erwei.png')
    }
  }
}
</script>
<style lang="less" scoped>
ul {
  // display: flex;
  // justify-content: space-around;
  // align-items: flex-start;
  // flex-wrap: wrap;
  padding: 10px 25px;
  li {
    // width: 30%;
    height: 120px;
    line-height: 120px;
    // background-color: purple;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 20px;
  }
}
.img_resize {
  width: auto;
  height: auto;
  max-width: 140px;
  max-height: 140px;
}
</style>